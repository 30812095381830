import React, { useState } from "react";
import AutoCompleteSearch from "../common/AutoCompleteSearch";
import data from "./data.json";
import DateInput from "./DateInput";
import { Cross } from "../common/Icons";
import Multicity from "./Multicity";
const TripInfo = ({ trip, setFormdata, formdata }) => {
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setFormdata(() => ({ ...formdata, [name]: value }));
  };

  return (
    <div className="flex">
      {trip == "One way" && (
        <div className="min-w-full grid grid-cols-1 md:grid-cols-3 gap-4">
          <AutoCompleteSearch
            inputValue={formdata && formdata.from}
            objectKey="from"
            setInputValue={setFormdata}
            options={data}
            keyLabel="From"
            formdata={formdata}
          />{" "}
          <AutoCompleteSearch
            inputValue={formdata && formdata.toValue}
            objectKey="toValue"
            setInputValue={setFormdata}
            options={data}
            keyLabel="To"
            formdata={formdata}
          />
          <DateInput
            text="Depart Date"
            selectedDate={formdata.departDate}
            handleInputChange={handleInputChange}
            name="departDate"
            trip={trip}
          />
        </div>
      )}

      {trip == "Round trip" && (
        <div className="min-w-full grid grid-cols-1 md:grid-cols-2 gap-4">
          <AutoCompleteSearch
            inputValue={formdata && formdata.from}
            objectKey="from"
            setInputValue={setFormdata}
            options={data}
            keyLabel="From"
            formdata={formdata}
          />{" "}
          <AutoCompleteSearch
            inputValue={formdata && formdata.toValue}
            objectKey="toValue"
            setInputValue={setFormdata}
            options={data}
            keyLabel="To"
            formdata={formdata}
          />
          <DateInput
            text="Depart Date"
            selectedDate={formdata.departDate}
            handleInputChange={handleInputChange}
            name="departDate"
            trip={trip}
          />
          <DateInput
            text="Return Date"
            selectedDate={formdata.returndate}
            handleInputChange={handleInputChange}
            name="returndate"
            trip={trip}
          />
        </div>
      )}

      {trip == "Multi city" && (
        <Multicity trip={trip} formdata={formdata} setFormdata={setFormdata} />
      )}
    </div>
  );
};

export default TripInfo;
