import React from "react";
import AutoCompleteSearch from "../common/AutoCompleteSearch";
import MulticityInputSearch from "./MulticityInputSearch";
import data from "./data.json";
import DateInput from "./DateInput";
import { Cross } from "../common/Icons";
import MulticityDateInput from "./MulticityDateInput";
const Multicity = ({ formdata, setFormdata, trip }) => {
  const deleteLeg = (index) => {
    setFormdata(() => {
      const newMulticity = formdata.multicity.filter(
        (_, legIndex) => legIndex !== index
      );
      return {
        ...formdata,
        multicity: newMulticity,
      };
    });
  };

  const addLeg = () => {
    setFormdata(() => ({
      ...formdata,
      multicity: [
        ...formdata.multicity,
        { from: "", toValue: "", departDate: "" },
      ],
    }));
  };

  return (
    <div className="flex min-w-full flex-col gap-4">
      {formdata &&
        formdata.multicity &&
        formdata.multicity.map((leg, index) => (
          <div
            key={index}
            className="min-w-full md:border-hidden rounded-lg p-6 md:p-0 border-blue border-[2px] relative grid grid-cols-1 md:grid-cols-3 gap-4"
          >
            <MulticityInputSearch
              inputValue={leg && leg.from}
              objectKey="from"
              setInputValue={setFormdata}
              options={data}
              keyLabel="From"
              index={index}
              formdata={formdata}
            />{" "}
            <MulticityInputSearch
              inputValue={leg && leg.toValue}
              objectKey="toValue"
              setInputValue={setFormdata}
              options={data}
              keyLabel="To"
              index={index}
              formdata={formdata}
            />
            <MulticityDateInput
              text="Depart Date"
              selectedDate={leg.departDate}
              setFormdata={setFormdata}
              formdata={formdata}
              name="departDate"
              trip={trip}
              index={index}
            />
            {index !== 0 && (
              <button
                className="rounded-lg border border-[#41546B80] h-[30px] right-2 md:-right-10 absolute top-2 md:top-[46%]"
                onClick={() => deleteLeg(index)}
                type="button"
              >
                <Cross />
              </button>
            )}
          </div>
        ))}

      <div className="flex">
        <button onClick={addLeg} class="px-4 py-2 bg-orange text-white ">
          Add leg
        </button>
      </div>
    </div>
  );
};

export default Multicity;
