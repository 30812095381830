import React, { useEffect, useState } from "react";
import { DropdownArrow } from "../common/Icons";

const Count = ({ formdata, setFormdata }) => {
  const data = ["Children", "Adult", "Other"];
  const [selected, setSelected] = useState(data[0]);
  const [show, setShow] = useState(false);
  const [counts, setCounts] = useState({
    Children: 0,
    Adult: 1,
    Other: 0,
  });

  const incrementCount = (option) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [option]: prevCounts[option] + 1,
    }));
  };

  const decrementCount = (option) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [option]: prevCounts[option] > 0 ? prevCounts[option] - 1 : 0,
    }));
  };

  useEffect(() => {
    const total = counts.Adult + counts.Children + counts.Other;
    setFormdata({ ...formdata, travelers: total });
  }, [counts.Children, counts.Adult, counts.Other]);
  return (
    <div className="relative max-w-[180px] min-w-[180px]">
      <h2
        className="cursor-pointer sm:px-2 max-w-[190px] py-2 flex items-center text-[#41546B] text-[16px] font-medium ff_poppins"
        onClick={() => setShow(!show)}
      >
        Traveler ({counts.Adult + counts.Children + counts.Other}){" "}
        <span className="ps-2">
          <DropdownArrow />
        </span>
      </h2>
      {show && (
        <ul className="border-t-[1px] border-b-[1px] border-orange py-1 absolute w-full z-10 bg-white shadow-xl">
          {data.map((option, i) => (
            <li
              className="cursor-pointer py-1  px-3 flex justify-between items-center"
              onClick={() => {
                setSelected(option);
                setShow(false);
              }}
              key={i}
            >
              <span className="min-w-[80px] inline-block">{option}</span>
              <div className="flex items-center">
                <button
                  type="button"
                  className="w-[25px] h-[25px] bg-orange"
                  onClick={(e) => {
                    e.stopPropagation();
                    decrementCount(option);
                  }}
                >
                  -
                </button>
                <span className="px-2">{counts[option]}</span>
                <button
                  type="button"
                  className="w-[25px] h-[25px] bg-orange"
                  onClick={(e) => {
                    e.stopPropagation();
                    incrementCount(option);
                  }}
                >
                  +
                </button>
              </div>
            </li>
          ))}
          <li className="px-3 text-center py-1">
            <button
              type="button"
              onClick={() => {
                setShow(false);
              }}
              className="text-red-500"
            >
              Close
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Count;
