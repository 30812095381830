const CommonParagraph = ({ className, text }) => {
  return (
    <p
      className={`font-poppins lg:text-lg text-base lg:leading-md leading-6 font-normal text-gray ${className}`}
    >
      {text}
    </p>
  );
};

export default CommonParagraph;
