import { useState } from "react";
import { ButtonIcon, NavCross, NavLine } from "./Icons";
import logo from "../../assets/images/svg/logo.svg";
import { useNavigate } from "react-router";

const Header = () => {
  const navigate = useNavigate();
  const [show, setfirst] = useState(false);

  if (show === true) {
    document.body.classList.add("max-lg:overflow-hidden");
  } else {
    document.body.classList.remove("max-lg:overflow-hidden");
  }
  let path = window.location.pathname;

  const handleNavClick = (sectionId) => {
    if (window.location.pathname !== "/") {
      navigate("/", { state: { scrollTo: sectionId } });
    } else {
      scrollToSection(sectionId);
    }
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="bg-white">
      <div className="px-5 sm:px-[80px] py-3 2xl:py-5 max-w-[1920px] mx-auto">
        <div className="flex justify-between items-center">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              className="cursor-pointer md:w-[200px] w-[150px] xl:w-auto"
            />
          </a>
          <ul
            className={`${
              show ? "inset-0" : "inset-full"
            } flex items-center gap-[40px] xl:gap-[40px] 2xl:gap-[80px] max-xl:fixed max-xl:top-0 max-xl:right-full max-xl:w-full max-xl:h-full max-xl:bg-white max-xl:flex max-xl:items-center max-xl:justify-center max-xl:flex-col max-xl:z-10 max-xl:transition-all max-xl:duration-300 max-xl:ease-linear `}
          >
            <li
              className="relative group"
              onClick={() => {
                setfirst(!show);
              }}
            >
              <a
                onClick={() => handleNavClick("#feature")}
                href="/#features"
                className="font-medium text-2xl leading-[24px] text-gray duration-300 hover:opacity-60"
              >
                About Us
              </a>
            </li>
            <li className=" flex gap-1 items-center relative group">
              <a
                href="/#services"
                className="font-medium text-2xl leading-[24px] text-gray duration-300 hover:opacity-60"
                onClick={() => {
                  handleNavClick("#services");
                  setfirst(!show);
                }}
              >
                Services
              </a>
            </li>
            <li className=" relative group">
              <a
                href="/#contact"
                className={`font-medium text-2xl leading-[24px] text-gray duration-300 hover:opacity-60 ${
                  path === "/contact-us"
                    ? "underline decoration-[3px] underline-offset-8"
                    : ""
                }`}
                onClick={() => {
                  handleNavClick("#contact");
                  setfirst(!show);
                }}
              >
                Contact
              </a>
            </li>
            <li className=" relative group">
              <a
                href="/contact-us"
                className="font-medium text-2xl leading-[24px] text-gray duration-300 hover:opacity-60"
                onClick={() => {
                  setfirst(!show);
                }}
              >
                Get a Quote
              </a>
            </li>
            <li className="md:hidden">
              <a
                href="tel:1888-660-4970"
                className="py-[12.5px] px-[20px] flex bg-gray h-[55px] gap-[10px] items-center font-jost font-medium text-white text-lg lg:text-2xl duration-300 hover:text-gray group hover:bg-white hover:shadow-lg hover:border-gray border border-transparent"
              >
                <ButtonIcon />
                <span>1888-660-4970</span>
              </a>
            </li>
          </ul>
          <a
            href="tel:1888-660-4970"
            className="py-[12.5px] px-[20px] md:flex bg-gray h-[55px] gap-[10px] items-center font-jost font-medium text-white text-lg lg:text-2xl hidden duration-300 hover:text-gray group hover:bg-white hover:shadow-lg hover:border-gray border border-transparent"
          >
            <ButtonIcon />
            <span>1888-660-4970</span>
          </a>
          <div
            className="z-10 bg-red xl:hidden cursor-pointer rounded-[10px]"
            onClick={() => {
              setfirst(!show);
            }}
          >
            <span>{show === false ? <NavLine /> : <NavCross />}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
