import React, { useEffect } from "react";
import Count from "./Count";
import { DropdownArrow } from "../common/Icons";

const FirstTwoDropdowns = ({
  show,
  setShow,
  showSeat,
  setShowSeat,
  CLASS_OPTIONS,
  TRIP_OPTIONS,
  setSeat,
  seat,
  setTrip,
  trip,
  formdata,
  setFormdata,
}) => {
  useEffect(() => {
    setFormdata({ ...formdata, tripValue: trip, classValue: seat });
  }, [seat, trip]);
  return (
    <>
      <div className="flex lg:gap-6 sm:gap-4 gap-1 flex-wrap">
        <div className="relative max-w-[100px] min-w-[130px]">
          <h2
            className="py-2 sm:px-2 cursor-pointer flex items-center text-[#41546B] text-[16px] font-medium ff_poppins"
            onClick={() => setShow(!show)}
          >
            {trip}
            <span className="ps-2">
              <DropdownArrow />
            </span>
          </h2>
          {show && (
            <ul className="border-t-[1px] border-b-[1px] border-orange py-1 absolute w-full z-10 bg-white shadow-xl px-2">
              {TRIP_OPTIONS.map((obj, i) => (
                <li
                  key={i}
                  className="py-1"
                  onClick={() => {
                    setTrip(obj);
                    setShow(false);
                  }}
                >
                  <input
                    checked={trip === obj}
                    type="radio"
                    name="trip"
                    id={obj}
                    className="cursor-pointer"
                  />
                  <label className="ps-2 cursor-pointer" htmlFor={obj}>
                    {obj}
                  </label>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="relative max-w-[160px] min-w-[160px]">
          <h2
            className="py-2 sm:px-2 cursor-pointer flex items-center text-[#41546B] text-[16px] font-medium ff_poppins"
            onClick={() => setShowSeat(!showSeat)}
          >
            {seat}{" "}
            <span className="ps-2">
              <DropdownArrow />
            </span>
          </h2>
          {showSeat && (
            <ul className="border-t-[1px] border-b-[1px] border-orange py-1 absolute w-full z-10 bg-white shadow-xl px-2">
              {CLASS_OPTIONS.map((obj, i) => (
                <li
                  onClick={() => {
                    setSeat(obj);
                    setShowSeat(false);
                  }}
                  className="py-1"
                >
                  <input
                    key={i}
                    checked={seat === obj}
                    type="radio"
                    name="class"
                    id={obj}
                    className="cursor-pointer"
                  />
                  <label className="ps-2 cursor-pointer" htmlFor={obj}>
                    {obj}
                  </label>
                </li>
              ))}
            </ul>
          )}
        </div>
        <Count formdata={formdata} setFormdata={setFormdata} />
      </div>
    </>
  );
};

export default FirstTwoDropdowns;
