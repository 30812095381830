import { HAPPY_TRAVELLER } from "../common/Helper";
import Quotation from "../../assets/images/png/quotation-img.png";
import FiveStars from "../../assets/images/png/five-stars.png";
import PrimaryHeading from "../common/PrimaryHeading";
import CommonParagraph from "../common/CommonParagraph";

const HappyTravelers = () => {
  return (
    <div className="max-w-[1800px] mx-auto xl:mt-[120px] md:mt-20 mt-16 px-5 sm:px-[80px]">
      <PrimaryHeading className="md:text-start text-center 2xl:mb-5 mb-3">
        Happy&nbsp;
        <span className="common_heading">Travelers</span>
      </PrimaryHeading>
      <div className="flex items-center md:justify-start justify-center 2xl:mb-[70px] lg:mb-[60px] md:mb-[50px] mb-[40px]">
        <CommonParagraph
          text="Experience the joy of traveling with Clever Travel Solutions through the words of our satisfied clients. Here’s what they have to say about their journeys with us:"
          className="max-w-[915px] md:text-start text-center"
        />
      </div>
      <div className="flex flex-wrap -mx-3 flex-row items-center justify-center">
        {HAPPY_TRAVELLER.map((obj, index) => (
          <div
            key={index}
            className="px-3 xl:w-4/12 md:w-6/12 w-full mt-5 flex items-center justify-center"
          >
            <div className="flex bg-light_orange flex-col items-start sm:p-[20px] 2xl:p-[25px] p-4 max-md:max-w-[500px] w-full sm:min-h-[470px]">
              <div className=" flex items-center justify-between w-full pb-5 2xl:pb-8">
                <div className="w-[30px] h-[26px]">
                  <img
                    src={Quotation}
                    alt="quotation-image"
                    className="w-full h-full pointer-events-none"
                  />
                </div>

                <div className="w=[100px] h-[16px]">
                  <img
                    src={FiveStars}
                    alt="five-star-image"
                    className="w-full h-full pointer-events-none"
                  />
                </div>
              </div>
              <h4 className="uppercase 2xl:text-[30px] xl:text-[28px] text-[25px] text-[#41546B] mb-3 py-2">
                {obj.title}
              </h4>
              <div className="flex items-center justify-center gap-4">
                <div className="flex flex-col pb-1">
                  <p className="font-josefin font-medium text-[#41546B] lg:text-2xl text-[22px] leading-6 ">
                    {obj.traveller_name}
                  </p>
                  <p className="ff_poppins font-medium lg:text-xl text-[18px] text-orange leading-5">
                    {obj.traveller_occup}
                  </p>
                </div>
              </div>
              <CommonParagraph
                className="max-w-[400px] 2xl:max-w-full h-[210px] 2xl:mt-5 mt-3 overflow-y-scroll customScroll ff_poppins pb-3 "
                text={obj.description}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HappyTravelers;
