import { useState } from "react";
import PrimaryHeading from "./PrimaryHeading";
import logo from "../../assets/images/svg/page-logo.svg";
import { AttachFile } from "./Icons";
import CommonButton from "../common/CommonButton";
import { CONTACT_US, SOCIAL_LINK } from "./Helper";
import imageDecorator from "../../assets/images/contact/png/image-decorater.png";
import emailjs, { init } from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

init("fyFv-ZDd8E_vTTOoS");

const GetInTouch = () => {
  const SERVICE_ID = "service_vufsiuk";
  const TEMPLATE_ID = "template_0hy6k59";
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    message: "",
  });
  const [error, setError] = useState(false);
  const [fileName, setFileName] = useState("Attach File");

  const HANDLE_FILE_CHANGES = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (formData.email && formData.firstName) {
      emailjs.send(SERVICE_ID, TEMPLATE_ID, formData).then((result) => {
        toast("submitted successfully!");
        setFormData({
          firstName: "",
          email: "",
          message: "",
        });
        setFileName("Attach File");
        setError(false);
      });
    }
  };

  let date = new Date().getFullYear();

  return (
    <div id="contact" className="xl:pt-[120px] md:pt-20 pt-16 overflow-hidden">
      <div className="max-w-[1800px] mx-auto px-5 sm:px-[80px]">
        {/* ================== */}
        <div className="flex xl:flex-row flex-col max-xl:max-w-[832px] max-xl:mx-auto relative 2xl:min-h-[615px] min-h-[558px] z-20">
          <div className="2xl:max-w-[1038px] max-w-[832px] w-full 2xl:px-[60px] 2xl:pt-[56px] sm:p-[50px] p-5 bg-white border border-blue">
            <div className="flex items-center justify-between">
              <PrimaryHeading className=" max-sm:!text-[28px]">
                Get In&nbsp;
                <span className="common_heading">Touch</span>
              </PrimaryHeading>
              <img
                src={logo}
                alt="logo-image"
                className="2xl:w-[200px] md:w-[150px] w-[100px] cursor-pointer"
              />
            </div>
            <form className="w-full" onSubmit={submitHandler}>
              <div className="flex items-center justify-between w-full gap-5 2xl:mt-[60px] lg:mt-10 sm:mt-8 mt-6 xl:mb-7 sm:mb-6 mb-5 sm:flex-nowrap flex-wrap">
                <div className="flex flex-col items-start justify-center w-full relative max-sm:mb-3">
                  <p className="font-poppins font-medium text-base leading-4 text-gray sm:mb-4 mb-2">
                    Full Name
                  </p>
                  <input
                    type="text"
                    placeholder="Enter First Name..."
                    className="border h-11 border-blue w-full p-[15px] outline-none"
                    value={formData.firstName}
                    onChange={(e) =>
                      setFormData({ ...formData, firstName: e.target.value })
                    }
                  />
                  {error && formData.firstName === "" && (
                    <p className="text-red-500 mt-2 absolute left-0 -bottom-6">
                      Please enter a first name.
                    </p>
                  )}
                </div>
                <div className="flex flex-col items-start justify-center w-full relative max-sm:mb-3">
                  <p className="font-poppins font-medium text-base leading-4 text-gray sm:mb-4 mb-2">
                    Email
                  </p>
                  <input
                    type="email"
                    placeholder="Enter Email..."
                    className="border w-full h-11 border-blue p-[15px] outline-none"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                  {error && formData.email === "" && (
                    <p className="text-red-500 mt-2 absolute -bottom-6 left-0">
                      Please enter an email address.
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-start justify-center">
                <p className="font-poppins font-medium text-base leading-4 text-gray sm:mb-4 mb-2">
                  Message
                </p>
                <textarea
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                  value={formData.message}
                  placeholder="Write a message..."
                  className="w-full border border-blue outline-none resize-none px-4 py-3 md:h-[88px] h-20"
                />
              </div>
              <div className="flex items-center justify-between mt-7">
                <div className="flex items-center sm:gap-4 gap-2">
                  <input
                    type="file"
                    id="file-upload"
                    className="hidden"
                    onChange={HANDLE_FILE_CHANGES}
                  />
                  <label
                    htmlFor="file-upload"
                    className="cursor-pointer flex items-center"
                  >
                    <AttachFile />
                    <p className="font-poppins font-medium sm:text-2xl text-lg leading-6 text-gray ml-2">
                      {fileName}
                    </p>
                  </label>
                </div>
                <CommonButton
                  text="Send"
                  className="xl:!py-5 2xl:!py-6 !border-transparent !bg-orange hover:!border-orange hover:!bg-white hover:!text-orange"
                  type="submit"
                />
              </div>
            </form>
          </div>
          {/* frame-image */}
          <div className="absolute top-1/2 -translate-y-1/2 -z-20 right-[0] sm:right-[-5%] w-[522px] h-[522px] max-lg:hidden">
            <img src={imageDecorator} alt="image decorator" />
          </div>
          {/* blue-card */}
          <div className="2xl:max-w-[574px] xl:max-w-[500px] min-h-full bg-blue w-full bg-get_in_touch bg-full bg-center relative 2xl:p-[60px] sm:p-[50px] p-5">
            <PrimaryHeading className="2xl:mb-[58px] mb-7">
              <span className="common_heading">Contact</span>
            </PrimaryHeading>
            <div className="max-xl:flex max-sm:flex-col max-xl:flex-wrap max-xl:justify-between max-xl:gap-5">
              {CONTACT_US.map((obj, i) => (
                <div className="xl:mb-9 flex flex-col gap-4" key={i}>
                  <a
                    href={obj.href}
                    className="hover:!text-orange ease-in-out duration-300 font-jost font-medium md:text-3xl text-2xl leading-7 text-white underline underline-offset-4 decoration-[2px]"
                  >
                    {obj.mainContenet}
                  </a>
                  <a
                    href={obj.href}
                    className="text-white font-normal md:text-lg text-base leading-[18px] xl:h-[18px] font-poppins text-gray-400"
                  >
                    {obj.subContent}
                  </a>
                </div>
              ))}
            </div>
            <div className="flex items-center justify-start gap-4 xl:mt-10 lg:mt-8 mt-6">
              {SOCIAL_LINK.map((obj, index) => (
                <a
                  key={index}
                  href="#"
                  className="size-10 border rounded-full flex items-center justify-center border-white ease-in-out duration-300 social-icon"
                >
                  <img
                    src={obj.socialHandle}
                    alt="social media handle"
                    className={`social-icon-${obj.name}`}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
        {/* ==================== */}
      </div>
      {/* bottom line */}
      <div className="max-w-[1735px] mx-auto mb-7 2xl:px-[50px] lg:px-[80px] px-5">
        <div className="w-full h-px bg-blue mt-16 mb-7"></div>
        <div className="flex items-center md:justify-between justify-center gap-5 md:flex-nowrap flex-wrap 2xl:px-0 px-3">
          <p className="font-normal font-poppins text-base text-gray text-center">
            Copyright © {date} Clever Travel - All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
