import concierge from "../../assets/images/webp/concierge.webp";
import efficient from "../../assets/images/webp/efficient.webp";
import hotel from "../../assets/images/webp/hotel.webp";
import travel from "../../assets/images/webp/luxury.webp";
import seamless from "../../assets/images/webp/seamless.webp";
import reverseFrame from "../../assets/images/webp/solution-frame-2.webp";
import frame from "../../assets/images/webp/solution-frame.webp";
import vip from "../../assets/images/webp/vip.webp";
// marque helper imports
import airCanada from "../../assets/images/png/partner-air-canada.png";
import airways from "../../assets/images/png/partner-airwayas.png";
import alaska from "../../assets/images/png/partner-alaska.png";
import british from "../../assets/images/png/partner-british.png";
import jetBlue from "../../assets/images/png/partner-jet-blue.png";
import koreanAir from "../../assets/images/png/partner-korean-air.png";
import marriott from "../../assets/images/png/partner-marriott.png";
import delta from "../../assets/images/png/partners-delta.png";
import hawaii from "../../assets/images/png/partners-hawaii.png";
import partnerHotel from "../../assets/images/png/partners-hotel.png";
import italic from "../../assets/images/png/partners-italic.png";
import pacific from "../../assets/images/png/partners-pacific.png";
import qantas from "../../assets/images/png/partners-qantas.png";
import thai from "../../assets/images/png/partners-thai.png";
import penStregis from "../../assets/images/png/pen-stregis.png";
// happy traveller imports

import luxury from "../../assets/images/svg/luxuary-icon.svg";
import { ConvenienceIcon, ExpertiseIcon, SavingsIcon } from "./Icons";
// contact us imports
import facebook from "../../assets/images/contact/svg/facebook.svg";
import instagram from "../../assets/images/contact/svg/instagram.svg";
import linkedin from "../../assets/images/contact/svg/linkedin.svg";
import twitter from "../../assets/images/contact/svg/twitter.svg";

import { FromInputIcon, ToInputIcon } from "./Icons";

export const MARQUE_ITEMS = [
  {
    marImg: partnerHotel,
  },
  {
    marImg: airCanada,
  },
  {
    marImg: jetBlue,
  },
  {
    marImg: pacific,
  },
  {
    marImg: thai,
  },
  {
    marImg: hawaii,
  },
  {
    marImg: italic,
  },
  {
    marImg: delta,
  },
  {
    marImg: qantas,
  },
  {
    marImg: airways,
  },
  {
    marImg: alaska,
  },
  {
    marImg: british,
  },
  {
    marImg: koreanAir,
  },
  {
    marImg: marriott,
  },
  {
    marImg: penStregis,
  },
];
export const HAPPY_TRAVELLER = [
  {
    title: "Family Vacation",
    traveller_name: "Emily Roberts",
    traveller_occup: "Marketing Director ",
    description:
      "Flying first class with Clever Travel was an unparalleled experience! We thoroughly enjoyed relaxing in the lounge and appreciated all the extra perks during our flight. Highly recommend!",
  },
  {
    title: "Airport VIP Service",
    traveller_name: "David Johnson",
    traveller_occup: "Senior Executive ",
    description:
      "From the very first call, the travel concierge team at Clever Travel took care of every detail, ensuring everything was perfectly arranged. The entire process was incredibly smooth, and we can’t wait to use them again for our next trip!",
  },
  {
    title: "Hotel Arrangements",
    traveller_name: "Sarah Lee",
    traveller_occup: "HR Manager ",
    description:
      "The corporate group travel service from Clever Travel exceeded our expectations. They took care of every detail, letting us concentrate on the reason for our trip. We’re grateful for the enjoyable and productive experience.",
  },
  {
    title: "Luxury Transportation",
    traveller_name: "The Carter Family",
    traveller_occup: "-----",
    description:
      "Our family vacation with Clever Travel was fantastic! The team curated every aspect of our trip, from accommodations to activities, making it a stress-free experience. They ensured that every member of our family, from the youngest to the oldest, had a wonderful time. We’re already planning our next getaway with them!",
  },
  {
    title: "Business Class",
    traveller_name: "Michael Thompson",
    traveller_occup: "Entrepreneur ",
    description:
      "Clever Travel's airport VIP service was a total game-changer. We skipped the lines and had all the paperwork handled for us. It made our travel experience so much more relaxing. Thank you!",
  },
  {
    title: "Couples Vacation",
    traveller_name: "Laura Bennett",
    traveller_occup: "Travel Blogger ",
    description:
      "The hotel arrangements made by Clever Travel were exceptional. Every property was top-notch and perfectly suited to our needs. Their attention to detail ensured we had a comfortable and enjoyable stay at each location. Highly recommend their hotel services!",
  },
];
export const SOLUTION_DATA = [
  {
    icon: <ConvenienceIcon />,
    cardClass: "sm:min-h-[250px]",
    title: "Convenience",
    text: "Simplify your travel planning with a one-stop solution that handles all the details, from booking flights to arranging accommodations.",
  },
  {
    img: luxury,
    cardClass: "sm:min-h-[220px]",
    title: "Luxury",
    text: "Indulge in personalized, high-end travel experiences tailored to your preferences.",
  },
  {
    icon: <ExpertiseIcon />,
    cardClass: "sm:min-h-[250px]",
    title: "Expertise",
    text: "Benefit from the knowledge and insider insights of travel professionals who know the best destinations, deals, and itineraries.",
  },
  {
    icon: <SavingsIcon />,
    cardClass: "sm:min-h-[220px]",
    title: "Savings",
    text: "Maximize your travel budget with access to exclusive discounts and special deals.",
  },
];
export const OFFER_DATA = [
  {
    image: concierge,
    frameImage: frame,
    frameImgPosition: "2xl:-left-[128px] xl:-left-[110px] left-[25px]",
    imgCard: "xl:justify-start",
    blueCardClass: "xl:-left-[170px]",
    title: "Personalized Travel Concierge",
    content:
      "Enjoy tailored travel planning and expert support throughout your journey. Our concierge services ensure every detail is handled to your specifications, providing a seamless and personalized experience from start to finish.",
  },
  {
    columnClass: "xl:order-2 xl:!justify-end",
    columnClass2: "xl:order-1",
    blueCardClass: "z-10",
    imgCard: "xl:justify-end",
    image: travel,
    frameImage: reverseFrame,
    frameImgPosition: "2xl:-right-[128px] xl:-right-[110px] right-[25px]",
    title: "Luxury Travel Experiences",
    content:
      "Experience the height of luxury with bespoke travel arrangements and premium accommodations. From exclusive offers to customized itineraries, we deliver unparalleled comfort and sophistication for your ultimate enjoyment.",
  },
  {
    image: seamless,
    frameImage: frame,
    frameImgPosition: "2xl:-left-[128px] xl:-left-[110px] left-[25px]",
    imgCard: "xl:justify-start",
    blueCardClass: "xl:-left-[170px]",
    title: "Seamless Group Travel",
    content:
      "Effortlessly coordinate group trips with our comprehensive management services. Perfect for corporate events, family vacations, and special occasions, we ensure smooth arrangements and memorable experiences for all participants.",
  },
  {
    columnClass: "xl:order-2 xl:!justify-end",
    columnClass2: "xl:order-1",
    blueCardClass: "z-10",
    imgCard: "xl:justify-end",
    image: vip,
    frameImage: reverseFrame,
    frameImgPosition: "2xl:-right-[128px] xl:-right-[110px] right-[25px]",
    title: "VIP Airport Services",
    content:
      "Travel with ease using our VIP airport services, including priority check-in, exclusive lounge access, and personalized assistance. We handle the details to provide you with a stress-free and comfortable airport experience.",
  },
  {
    image: hotel,
    frameImage: frame,
    frameImgPosition: "2xl:-left-[128px] xl:-left-[110px] left-[25px]",
    imgCard: "xl:justify-start",
    title: "Curated Hotel Stays",
    blueCardClass: "xl:-left-[170px]",
    content:
      "Discover a range of exceptional lodging options carefully selected to meet your needs. From charming boutique hotels to luxurious resorts, we ensure your accommodations enhance your travel experience.",
  },
  {
    columnClass: "xl:order-2 xl:!justify-end",
    columnClass2: "xl:order-1",
    blueCardClass: "z-10",
    imgCard: "xl:justify-end",
    image: efficient,
    frameImage: reverseFrame,
    frameImgPosition: "2xl:-right-[128px] xl:-right-[110px] right-[25px]",
    title: "Efficient Transportation Solutions",
    content:
      "Simplify your travel with our reliable transportation options, including car rentals and airport transfers. We provide convenient and efficient solutions to keep you moving smoothly throughout your journey.",
  },
];
export const CONTACT_US = [
  {
    mainContenet: "Location:",
    subContent: "250 Skillman St suite 334 Brooklyn NY 11205",
    href: "/",
  },
  {
    mainContenet: "Email:",
    subContent: "Info@clevertravelsolutions.com",
    href: "mailto:Info@clevertravelsolutions.com",
  },
  {
    mainContenet: "Phone:",
    subContent: "1888-660-4970",
    href: "tel:1888-660-4970",
  },
];
export const SOCIAL_LINK = [
  {
    socialHandle: twitter,
    name: "twitter",
  },
  {
    socialHandle: linkedin,
    name: "linkedin",
  },
  {
    socialHandle: instagram,
    name: "instagram",
  },
  {
    socialHandle: facebook,
    name: "facebook",
  },
];
export const CONTACT_DROPDOWN = [
  {
    label: "Round Trip",
    options: ["Round Trip", "One Way", "Multi-City"],
  },
  {
    label: "Business Class",
    options: ["Economy", "Business Class", "First Class"],
  },
  {
    label: "1 Traveller",
    options: ["Children", "Adult", "Infants"],
  },
];
export const TRAVEL_DATA = [
  {
    icon: <FromInputIcon />,
    title: "From",
    placeholderText: "Enter Airport Name...",
    inputBoxClass: "w-full lg:max-w-[47.8%] md:max-w-[47.8%]",
    inputType: "text",
  },
  {
    icon: <ToInputIcon />,
    title: "To",
    placeholderText: "Enter Airport Name...",
    inputBoxClass: "w-full lg:max-w-[47.8%] md:max-w-[47.8%]",
    inputType: "text",
  },
];
export const PERSONAL_INFO = [
  {
    id: "firstName",
    title: "First Name",
    placeholderText: "Enter First Name...",
    inputBoxClass: "w-full lg:max-w-[47.8%] md:max-w-[47.8%]",
    inputType: "text",
  },
  {
    id: "lastName",
    title: "Last Name",
    placeholderText: "Enter Last Name...",
    inputBoxClass: "w-full lg:max-w-[47.8%] md:max-w-[47.8%]",
    inputType: "text",
  },
  {
    id: "contactNumber",
    title: "Contact Number",
    placeholderText: "Enter Contact Number...",
    inputBoxClass: "w-full lg:max-w-[47.8%] md:max-w-[47.8%]",
    inputType: "number",
  },
  {
    id: "email",
    title: "Email",
    placeholderText: "Enter Email...",
    inputBoxClass: "w-full lg:max-w-[47.8%] md:max-w-[47.8%]",
    inputType: "email",
  },
];
