import { Link } from "react-router-dom";
import logo from "../../assets/images/svg/logo.svg";
import CommonButton from "../common/CommonButton";

const Hero = () => {
  return (
    <div className="bg-[url(./assets/images/webp/hero-bg.webp)] max-2xl:min-h-[calc(100vh-104px)] 2xl:min-h-[calc(100vh-120px)] max-xl:min-h-fit min-h-[calc(100vh-120px)] bg-cover bg-center bg-no-repeat max-w-[1920px] flex items-center justify-start 2xl:mx-auto">
      <div className="pt-0 pb-0 max-xl:py-20 max-md:py-16 max-lg:px-5">
        <div className=" 2xl:max-w-[1100px] max-w-[720px]   py-12 2xl:my-1 sm:ps-10 ps-5 lg:ps-16 pe-5  max-md:py-5 2xl:py-16  3xl:px-16  bg-light_black">
          <img
            src={logo}
            alt="logo"
            className="w-[200px] md:w-auto cursor-pointer"
          />
          <h2 className="font-jost font-medium text-[35px] md:text-[65px] md:leading-[70px] 2xl:text-[84px] leading-[42px] 2xl:leading-[110%] mt-6 text-white max-w-[800px]">
            Escape to Paradise with&nbsp;
            <span className="text-orange relative underline decoration-[3px] underline-offset-8">
              Clever Travel
            </span>
          </h2>
          <p className="max-w-[550px] font-poppins font-normal text-base md:text-lg text-white mt-5 sm:pb-4">
            Discover the peace and beauty of breathtaking destinations,
            meticulously selected by our travel professionals to offer you a
            relaxing and luxurious escape
          </p>
          <Link to="/contact-us">
            <CommonButton
              text="Book Now"
              className="xl:!py-5 2xl:!py-6 mt-5 !border-transparent !bg-orange hover:!border-orange hover:!bg-white hover:!text-orange"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
