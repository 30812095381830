import { useState } from "react";
import logo from "../../assets/images/svg/page-logo.svg";
import reverseFrame from "../../assets/images/webp/solution-frame-2.webp";
import frame from "../../assets/images/webp/solution-frame.webp";
import PrimaryHeading from "../common/PrimaryHeading";
import Destination from "./Destination";
import PersonalInfo from "./PersonalInfo";
import FirstTwoDropdowns from "./FirstTwoDropdowns";
import emailjs, { init } from "@emailjs/browser";
import { toast } from "react-toastify";
import AutoCompleteSearch from "../common/AutoCompleteSearch";
import data from "./data.json";
import TripInfo from "./TripInfo";
const TRIP_OPTIONS = ["Round trip", "One way", "Multi city"];
const CLASS_OPTIONS = ["First class", "Business class", "Premium"];

const ContactForm = () => {
  const [trip, setTrip] = useState(TRIP_OPTIONS[0]);
  const [show, setShow] = useState(false);
  const [seat, setSeat] = useState(CLASS_OPTIONS[0]);
  const [showSeat, setShowSeat] = useState(false);

  const initialValue = {
    tripValue: "Round trip",
    classValue: "First class",
    multicity: [
      { from: "", toValue: "", departDate: "" }, // Initial leg
    ],
    travelers: 1,
    from: "",
    toValue: "",
    departDate: "",
    returndate: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
  };
  const [formdata, setFormdata] = useState(initialValue);
  init("fyFv-ZDd8E_vTTOoS");
  const SERVICE_ID = "service_vufsiuk";
  const TEMPLATE_ID = "template_k5gwhar";
  const onSubmitHandler = (e) => {
    e.preventDefault();

    let detailsMsg;
    let data;
    if (trip == "Round trip") {
      detailsMsg = `Dear Admin,\n\nPlease find the flight details for Round trip as follows:\n\nFrom: ${formdata.from}\nTo: ${formdata.toValue}\nDepart Date: ${formdata.departDate}\nReturn Date: ${formdata.returndate}\nEconomy: ${formdata.classValue}\nPassengers: ${formdata.travelers}\n\nThank you for processing this information.`;
    }

    if (trip == "One way") {
      detailsMsg = `Dear Admin,\n\nPlease find the details for the one way trip as follows:\n\nFrom: ${formdata.from}\nTo: ${formdata.toValue}\nDepart Date: ${formdata.departDate}\nEconomy: ${formdata.classValue}\nPassengers: ${formdata.travelers}\n\nThank you for processing this information.`;
    }

    if (trip == "Multi city") {
      detailsMsg = `Dear Admin,\n\nPlease find the details for the multi-city trip as follows:\n\n${formdata.multicity
        .map(
          (leg, index) =>
            `Leg ${index + 1}:\nFrom: ${leg.from}\nTo: ${
              leg.toValue
            }\nDepart Date: ${leg.departDate}\nEconomy: ${
              formdata.classValue
            }\nPassengers: ${formdata.travelers}\n`
        )
        .join("\n")}\nThank you for processing this information.`;
    }
    data = {
      flightDetails: detailsMsg,
      firstName: formdata.firstName,
      lastName: formdata.lastName,
      phoneNumber: formdata.phoneNumber,
      email: formdata.email,
      message: formdata.message,
    };
    emailjs.send(SERVICE_ID, TEMPLATE_ID, data).then((result) => {
      toast("submitted successfully!");
      setFormdata(initialValue);
    });
  };

  return (
    <div className="2xl:max-w-[1920px] mx-auto xl:pb-[120px] md:pb-20 pb-16">
      <div className="container max-w-[1800px] px-5">
        <div className="relative w-full xl:max-w-[1166px] mx-auto">
          <div className="max-w-[825px]  2xl:py-14 2xl:px-[60px] sm:p-6 p-3 md:p-14 border border-blue mx-auto bg-white -mt-[75px] sm:-mt-[110px] flex flex-col 2xl:gap-[60px] sm:gap-[50px] gap-10">
            <div className="flex justify-between gap-8">
              <PrimaryHeading>
                <span className="common_heading">Quote</span>
              </PrimaryHeading>

              <div className="md:w-[273px] md:h-[80px] sm:w-[220px] sm:h-[60px] w-[175px] h-[50px]">
                <img src={logo} alt="logo" className="w-full" />
              </div>
            </div>
            <form onSubmit={onSubmitHandler}>
              {/* Travel Info section */}
              <div className="flex flex-col md:gap-[30px] gap-[26px] md:mb-[60px] mb-[50px]">
                <p className="font-josefin w-fit font-semibold md:text-3xl text-[28px] md:leading-md leading-9 text-light_blue relative underline decoration-[1px] underline-offset-4">
                  Travel Info
                </p>

                {/* =========================================DROPDOWNS========================================== */}
                <FirstTwoDropdowns
                  show={show}
                  setShow={setShow}
                  showSeat={showSeat}
                  setShowSeat={setShowSeat}
                  TRIP_OPTIONS={TRIP_OPTIONS}
                  CLASS_OPTIONS={CLASS_OPTIONS}
                  trip={trip}
                  setTrip={setTrip}
                  seat={seat}
                  setSeat={setSeat}
                  formdata={formdata}
                  setFormdata={setFormdata}
                />
                {/* <Count counts={counts} setCounts={setCounts} /> */}
                {/* =========================================DROPDOWNS========================================== */}

                {/* <Destination
                  trip={trip}
                  setFormdata={setFormdata}
                  formdata={formdata}
                /> */}
                <TripInfo
                  trip={trip}
                  setFormdata={setFormdata}
                  formdata={formdata}
                />
              </div>
              <PersonalInfo formdata={formdata} setFormdata={setFormdata} />
            </form>
          </div>
          <img
            src={frame}
            alt="frame"
            className="w-full max-w-[522px] pointer-events-none hidden lg:block absolute top-[230px] xl:left-0 -left-4 -z-10"
          />
          <img
            src={reverseFrame}
            alt="frame"
            className="w-full max-w-[522px] pointer-events-none hidden lg:block absolute top-[230px] xl:right-0 -right-4 -z-10"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
