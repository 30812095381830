const PrimaryHeading = ({ children, className }) => {
  return (
    <h2
      className={`font-medium 2xl:text-6xl xl:text-[52px] md:text-5xl sm:text-[40px] text-[32px] 2xl:leading-xl xl:leading-[68px] md:leading-[64px] sm:leading-[56px] leading-10 text-gray ${className}`}
    >
      {children}
    </h2>
  );
};

export default PrimaryHeading;
