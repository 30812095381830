import CommonParagraph from "../common/CommonParagraph";
import PrimaryHeading from "../common/PrimaryHeading";
import logo from "../../assets/images/svg/page-logo.svg";
import { SOLUTION_DATA } from "../common/Helper";
import SolutionImg from "../../assets/images/webp/solution-img.webp";

const TravelSolutions = () => {
  return (
    <div
      className="xl:pt-[120px] xl:pb-[124px] md:py-20 py-16 2xl:max-w-[1920px] mx-auto overflow-hidden"
      id="features"
    >
      <div className="container lg:max-w-[1024px] 2xl:max-w-[1440px] xl:max-w-[1200px] px-5">
        <div className="max-w-[954px] mx-auto text-center">
          <img
            src={logo}
            alt="logo-icon"
            width={273}
            height={80}
            className="mb-5 mx-auto pointer-events-none"
          />
          <PrimaryHeading>
            <span className="common_heading block">
              Clever Travel Solutions:
            </span>
            Your Trusted Travel Partner
          </PrimaryHeading>
          <CommonParagraph
            className="2xl:mt-5 sm:mt-3 mt-2"
            text="Clever Travel Solutions specializes in booking the best and most affordable flight options, particularly for international business and first-class travel. Our exceptional customer service ensures a seamless and enjoyable travel experience from start to finish."
          />
        </div>
        {/* row */}
        <div className="flex flex-row flex-wrap -mx-5 justify-between 2xl:mt-[93px] lg:mt-20 md:mt-[70px] mt-[60px] relative z-30">
          <div className="xl:w-1/3 sm:w-1/2 w-full px-5 max-sm:flex max-sm:justify-center">
            <div className="flex flex-col sm:gap-10 gap-8">
              {SOLUTION_DATA.slice(0, 2).map((value, index) => (
                <div key={index} className={`solution_card ${value.cardClass}`}>
                  <span>
                    {value.icon ? (
                      value.icon
                    ) : (
                      <img
                        src={value.img}
                        alt="solution-icon"
                        className="pointer-events-none"
                      />
                    )}
                  </span>
                  <p className="solution_title">{value.title}</p>
                  <CommonParagraph text={value.text} />
                </div>
              ))}
            </div>
          </div>
          <div className="xl:w-1/3 sm:w-1/2 w-full sm:mt-0 mt-8 px-5 flex sm:justify-end justify-center">
            <div className="flex flex-col sm:gap-10 gap-8">
              {SOLUTION_DATA.slice(2, 4).map((value, index) => (
                <div key={index} className={`solution_card ${value.cardClass}`}>
                  <span>{value.icon}</span>
                  <p className="solution_title">{value.title}</p>
                  <CommonParagraph text={value.text} />
                </div>
              ))}
            </div>
          </div>
          {/* solution-image-center  */}
          <div className="2xl:max-w-[588px] max-w-[500px] h-[517px] w-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none">
            <img
              src={SolutionImg}
              alt="solution-bg-image"
              width={588}
              height={517}
              className="max-xl:opacity-25 pointer-events-none"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelSolutions;
