import PrimaryHeading from "../common/PrimaryHeading";
import CommonParagraph from "../common/CommonParagraph";
import CommonButton from "../common/CommonButton";
import { OFFER_DATA } from "../common/Helper";
import { Link } from "react-router-dom";

const ServicesOffer = () => {
  return (
    <div className="2xl:max-w-[1920px] mx-auto overflow-hidden" id="services">
      <div className="container lg:max-w-[1024px] xl:max-w-[1200px] 2xl:max-w-[1504px] px-5">
        <div className="max-w-[954px] mx-auto text-center">
          <PrimaryHeading>
            <span className="common_heading">Services</span>
            &nbsp;We Offer
          </PrimaryHeading>
          <CommonParagraph
            className="2xl:mt-5 sm:mt-3 mt-2"
            text="We offer a comprehensive range of travel solutions designed to make your journey effortless and enjoyable. From personalized trip planning and exclusive deals to expert advice and 24/7 support, we handle every detail to ensure a seamless and memorable travel experience. Whether you need assistance with bookings, insurance, or custom experiences, our team is here to provide exceptional service tailored to your needs."
          />
        </div>
        {OFFER_DATA.map((value, index) => (
          <div
            key={index}
            className="flex flex-row flex-wrap -mx-5 2xl:mt-[90px] lg:mt-20 md:mt-[70px] mt-[60px]"
          >
            <div
              className={`xl:w-3/5 w-full px-5 ${value.columnClass} flex max-xl:justify-center`}
            >
              <div
                className={`relative w-full max-sm:max-w-[400px] flex justify-center max-xl:items-center max-w-[600px] 2xl:max-w-[832px] md:max-w-[690px] 2xl:h-[522px] md:h-[455px] sm:h-[380px] ${value.imgCard}`}
              >
                <img
                  src={value.frameImage}
                  alt="frame-image"
                  width={522}
                  height={522}
                  className={`w-full max-w-[522px] pointer-events-none hidden lg:block 2xl:h-[522px] h-[455px] absolute top-1/2 -translate-y-1/2 ${value.frameImgPosition} -z-10`}
                />
                <img
                  src={value.image}
                  alt="offer-image"
                  width={832}
                  height={522}
                  className="w-full max-2xl:object-cover pointer-events-none"
                />
              </div>
            </div>
            <div
              className={`xl:w-2/5 w-full px-5 ${value.columnClass2} max-sm:flex max-sm:justify-center`}
            >
              <div
                className={`2xl:w-[734px] md:w-[640px] xl:w-[609px] sm:w-[520px] max-sm:max-w-[400px] w-full 2xl:p-[60px] xl:p-12 sm:p-9 p-6 bg-offer_bg bg-light_blue bg-full relative xl:top-1/2 xl:-translate-y-1/2 max-xl:left-1/2 max-sm:left-0 max-xl:-translate-x-1/2 max-sm:translate-x-0 max-sm:top-0 max-xl:-top-14 ${value.blueCardClass}`}
              >
                <p className="font-josefin w-fit font-semibold md:text-3xl text-[28px] md:leading-md leading-9 text-orange relative 2xl:mb-5 mb-3 underline decoration-[1px] underline-offset-4">
                  {value.title}
                </p>
                <CommonParagraph
                  className="!text-white 2xl:mb-10 sm:mb-8 mb-6 h-[120px] overflow-y-scroll customScroll"
                  text={value.content}
                />
                <Link to="/contact-us">
                  <CommonButton text="Book Now" />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesOffer;
